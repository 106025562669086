<template>
    <div id="agenda-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack"> <i class="fa fa-chevron-left"></i> retour </b-button>
            Agenda
            <b-button id="modeDate" @click="changeMode"><span v-if="mode === 'month'">Semaine</span><span
                    v-else>Mois</span></b-button>
        </div>
        <div class="nav-calendar-wrapper form-group mt-5" v-if="mode === 'week'">
            <div class="nav-calendar">
                <div class="container-lg">
                    <div class="row align-items-center">
                        <div class="col-6 font-weight-bold text-info" v-if="selectedDate">
                            <h5 class="m-0">Semaine {{ selectedWeek }}</h5>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn p-0 mr-1 navWeek" @click="loadInterventionsByWeek('previous')">
                                <span class="fc-icon fc-icon-chevron-left"></span>
                            </button>
                            <button class="btn p-0 navWeek" @click="loadInterventionsByWeek('next')">
                                <span class="fc-icon fc-icon-chevron-right"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <FullCalendar v-if="false" ref="calendar" :options="calendarOptions" id="calendar"
                class="text-white form-group  Semaine" />

            <time-splitter :list="slots" split-by-month title="_Month_ _Y_" prop="date_slot" class="w-100"
                @change-month="changeMonth($event)" v-if="mode == 'month'">
                <template #default="splitterProps">
                    <app-calendar class="mt-1" :items="splitterProps.split" :date-action="(date) => {
                        changeDate({ dateStr: date });
                    }
                        "></app-calendar>
                </template>
            </time-splitter>

            <div class="col-12 col-lg-6">
                <!--<a href="#agenda" class="btn btn-success d-block btn-lg addAnAppointment">
                    <i class="far fa-calendar-alt" aria-hidden="true"></i> Ajouter un rendez-vous
                </a>-->
                <div class="form-group mt-4">
                    <div v-if="interventions.length > 0">
                        <div v-for="item in interventions" :key="item.day">
                            <h5 class="text-white dateIntervention">{{ formatDate(item.day, "dddd D MMMM") }}</h5>
                            <div v-if="item.clients.length > 0">
                                <div class="list-group text-dark mb-1" v-for="client in item.clients" :key="client.id">
                                    <router-link class="list-group-item list-group-item-action"
                                        :class="{ zebra: client.canceled_category }"
                                        :to="{ name: 'fiche-client', params: { clientId: client.customer_idcustomer, intervAdress: client.intervention_idadress } }">
                                        <div class="centered-ico col-2 bg-info-corporate">
                                            <i class="fas fa-lightbulb fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div class="align-items-center row text-dark">
                                            <div class="col col-lg-8 offset-2">
                                                <span>{{ getFormattedHour(client.date_slot, getStartTime(client))
                                                }}</span> -
                                                <span>{{ getFormattedHour(client.date_slot, getEndTime(client))
                                                }}</span>
                                                <div class="d-block text-truncate" style="max-width: 70vw">
                                                    {{ getFormattedTitle(client.customer_title) }} {{
                                                        getFormattedFirstname(client.customer_firstname) }}
                                                    {{ client.customer_lastname }}
                                                </div>
                                                <div>
                                                    <small class="text-muted">{{ client.adress1 }} {{ client.postal_code
                                                    }}
                                                        {{ client.city }}</small>
                                                </div>
                                                <div v-if="client.customer_instructions">
                                                    <strong>Consigne: </strong>{{ client.customer_instructions }}
                                                </div>
                                                <div v-if="client.canceled_category">
                                                    <strong>Prestation annulée</strong>
                                                    <span class="ml-1"
                                                        v-if="[2, 3].includes(client.canceled_category)">(payée)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div class="text-center text-white form-group" v-else>
                                <div class="bg-info p-3 rounded">
                                    <h6 class="m-0">Rien de prévu</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import router from "../../../router";
import planningService from "../../../services/planningService";
import AppCalendar from "./AppCalendar";
import TimeSplitter from "./TimeSplitter";

export default {
    name: "Agenda",
    components: {
        FullCalendar,
        TimeSplitter,
        AppCalendar,
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                locales: [frLocale],
                locale: "fr",
                headerToolbar: {
                    end: "prev,next",
                },
                dateClick: this.changeDate,
            },
            mode: "month",
            selectedDate: null,
            selectedWeek: null,
            interventions: [],
            slots: [],
        };
    },
    mounted() {
        this.$emit("menu", "agenda");
        this.changeMode();
    },
    methods: {
        getBack() {
            if (this.selectedInfo) {
                this.selectedInfo = null;
            } else {
                router.go(-1);
            }
        },
        formatDate(date, format) {
            return this.$moment(date).format(format);
        },
        getFormattedHour(date, hour) {
            return this.$moment(date + " " + hour).format("HH:mm");
        },
        changeDate(event) {
            this.selectedDate = event.dateStr;
            this.loadInterventionsByDate();
        },
        loadInterventionsByDate() {
            planningService
                .getPlanning(this.selectedDate, this.selectedDate)
                .then((response) => {
                    if (response.data.etat === "OK") {
                        this.interventions = [
                            {
                                day: this.selectedDate,
                                clients: response.data.data,
                            },
                        ];
                    }
                })
                .catch((error) => {
                    this.$rollbar.error("Erreur 3017", error);
                    this.$bvToast.toast("Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.", {
                        title: "Erreur 3017",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                });
        },
        changeMode() {
            if (this.mode === "month") {
                this.selectedDate = this.getDateFromWeek(1, this.$moment().week(), this.$moment().year());
                this.selectedWeek = this.$moment().week();
                this.mode = "week";
                this.loadInterventionsByWeek(null);
            } else {
                this.selectedDate = this.$moment().startOf("month").format("YYYY-MM-DD");
                this.mode = "month";
                this.loadInterventionsByWeek();
            }
        },
        loadInterventionsByWeek(type) {
            this.interventions = [];
            if (type === "next") {
                this.selectedDate = this.$moment(this.selectedDate).add(7, "days").format("YYYY-MM-DD");
            } else if (type === "previous") {
                this.selectedDate = this.$moment(this.selectedDate).subtract(7, "days").format("YYYY-MM-DD");
            }
            this.selectedWeek = this.$moment(this.selectedDate).week();

            let date = this.$moment(this.selectedDate).add(6, "days");
            if (!type) {
                date.endOf("month");
            }

            planningService
                .getPlanning(this.selectedDate, date.format("YYYY-MM-DD"))
                .then((response) => {
                    if (response.data.etat === "OK") {
                        if (response.data.data) {
                            this.slots = response.data.data;
                            response.data.data.forEach((item) => {
                                let day = this.interventions.find((i) => i.day === item.date_slot);
                                if (day === undefined) {
                                    this.interventions.push({ day: item.date_slot, clients: [] });
                                    day = this.interventions.find((i) => i.day === item.date_slot);
                                }
                                day.clients.push(item);
                                day.clients.sort((a, b) => {
                                    if (
                                        this.$moment(a.date_slot + " " + a.start_time).isBefore(
                                            this.$moment(b.date_slot + " " + b.start_time)
                                        )
                                    ) {
                                        return -1;
                                    } else {
                                        return 1;
                                    }
                                });
                            });
                            this.interventions.sort((a, b) => {
                                if (this.$moment(a.day).isBefore(this.$moment(b.day))) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.$rollbar.error("Erreur 3018", error);
                    this.$bvToast.toast("Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.", {
                        title: "Erreur 3018",
                        autoHideDelay: 5000,
                        variant: "danger",
                    });
                });
        },
        getDateFromWeek(day, week, year) {
            //return this.$moment().day(day).year(year).week(week).format("YYYY-MM-DD");
            return this.$moment().year(year).week(week).day(day).format("YYYY-MM-DD");
        },
        getFormattedFirstname(firstname) {
            if (firstname === null) return "";

            return firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();
        },
        changeMonth(dir) {
            this.selectedDate = this.$moment(this.selectedDate).add(dir, "month").format("YYYY-MM-DD");
            this.loadInterventionsByWeek();
        },
        getStartTime(client) {
            if (client.idanomaly) return client.start_time;
            return client.start_time_real ?? client.start_time;
        },
        getEndTime(client) {
            if (client.idanomaly) return client.end_time;
            return client.end_time_real ?? client.end_time;
        },
        getFormattedTitle(title) {
            if (!title || title === 'null') return ''
            return title + '.'
        }

    },
};
</script>

<style lang="scss" scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus {
    box-shadow: none;
}

/deep/ #calendar .fc-header-toolbar {
    min-height: 3rem;
    height: 3.2rem;
    min-width: 100%;
    background-image: url("/img/bg-agenda.33a2837c.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    color: #113573 !important;
    padding: 15px;
}

.zebra {
    background: repeating-linear-gradient(45deg, #cbf0ff, #cbf0ff 10px, #ffffff 10px, #ffffff 20px);
}

#modeDate {
    width: 82px;
}

.dateIntervention::first-letter {
    text-transform: uppercase;
}
</style>
